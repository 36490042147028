<template>
  <m-widget-container
    v-if="items !== null && items.length"
    class="information-container"
    :style="`
      --icon-color: ${getColor($vuetify.theme.themes.light, iconColor)};
      --title-color: ${getColor($vuetify.theme.themes.light, titleColor)};
      --text-color: ${getColor($vuetify.theme.themes.light, textColor)};
    `"
  >
    <v-window
      v-model="window"
      :vertical="vertical"
    >
      <v-window-item
        v-for="(item, i) in items"
        :key="i"
      >
        <v-alert
          prominent
          :color="backgroundColor"
          :dark="dark"
          class="item-alert"
          :icon="item.icon ? `mdi-${item.icon}` : 'mdi-alert'"
          tile
        >
          <div class="warning-title title font-weight-bold">
            {{ item.title }}
          </div>
          <div class="warning-subtitle">
            {{ item.subtitle }}
          </div>
        </v-alert>
      </v-window-item>
    </v-window>
  </m-widget-container>
</template>

<script>
import { mWidgetMixin } from '../../../mixins'
export default {
  name: 'MWarning',
  mixins: [mWidgetMixin],
  props: {
    items: {
      type: Array,
      default: () => []
    },
    intervalInSeconds: {
      type: [Number, String],
      default: () => 5
    },
    iconColor: {
      type: String,
      default: () => 'red'
    },
    titleColor: {
      type: String,
      default: () => 'red'
    },
    textColor: {
      type: String,
      default: () => ''
    },
    dark: {
      type: Boolean,
      default: () => false
    },
    vertical: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    window: 0
  }),
  mounted() {
    if (this.items.length > 1) {
      setInterval(() => {
        if (++this.window >= this.items.length) {
          this.window = 0
        }
      }, this.intervalInSeconds * 1000)
    }
  }
}
</script>
<style lang="sass">
  .v-alert
    font-size: 1rem
    padding: 1rem
    margin-bottom: 0
  .v-alert--prominent .v-alert__icon.v-icon
    font-size: 3rem
    height: 5rem
    min-width: 5rem
  .information-container
    pointer-events: none
    display: flex
    align-items: center
    height: 100%
    .v-window
      width: 100%
      height: 100%
      .v-window__container
        display: flex
        align-items: center
        height: 100%
        .v-window-item
          width: 100%
          height: 100%
          .v-alert, .v-alert__wrapper
            height: 100%
        .v-alert__icon
          color: var(--icon-color)!important
        .v-alert--prominent .v-alert__icon:after
          color: var(--icon-color)!important
        .warning-title
          color: var(--title-color)!important
        .warning-subtitle
          color: var(--text-color)!important
</style>
